import React from 'react'
import Container from '../container'

import * as styles from './cloudIndexNav.module.css'

const CloudIndexNav = ({ current, hide }) => {
  return (
    <div className={styles.nav}>
      {!hide && (
        <div className={styles.scroller}>
          <Container class={styles.containerPad}>
            <div className={styles.links}>
              <a href='/infrared/' className={current === 'index' ? styles.active : undefined}>The Nasdaq Redpoint Cloud Infrastructure Software Index</a>
              <a href='/infrared/constituents/' className={current === 'constituents' ? styles.active : undefined}>Index Constituents</a>
              <a href='/infrared/report/' className={current === 'report' ? styles.active : undefined}>The InfraRed Report</a>
              <a href='/infrared/100/' className={current === 'infrared' ? styles.active : undefined}>InfraRed 100</a>
            </div>
          </Container>
        </div>
      )}
    </div>
  )
}

export default CloudIndexNav
